.w-embed::after {
    clear: both;
    content: "";
    display: table;
}

.w-embed::before {
    content: "";
    display: table;
}

.flickity-viewport {
    height: 30vh;
    width: 100%;
    overflow: visible !important;
  }
  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  
  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  @media only screen and (min-width: 992px) {
.timeline-event.is-selected #event-year {
  transform: scale(1.3, 1.3);
}
  }
  .timeline-event.is-selected #event-year {
    background: linear-gradient(260.77deg, #53caee 0.05%, #ee46c4 99.93%);
      background-clip: border-box;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent !important;
  }

  #flickity-container:focus {
    outline: none;
  }


.noUi-base {
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .noUi-connects {
    border-radius: 100%;
    width: 100%;
    height: 1px;
    position: relative;
    z-index: 1;
    background-color: #808d8d;
    display: flex;
    align-items: center;
  }


  .noUi-connect {
    border-radius: 100%;
    background-color: white;
    will-change: transform;
    z-index: 1;
    height: 2px;
    width: 100%;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat;
  }


  .noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat;
  }

  .noUi-handle {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
  }

  .noUi-horizontal .noUi-handle.noUi-handle-lower {
    width: 96px;
    height: 38px;
    right: -48px;
    background-color: #53caee;
    border-radius: 56px;
  }


  .noUi-touch-area {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .slider {
    grid-column-gap: 1.25rem;
    display: flex;
  }

  .slider-arrow-svg {
    width: 1.25rem;
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
  }