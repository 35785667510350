.w-embed::after {
    clear: both;
    content: "";
    display: table;
}

.w-embed::before {
    content: "";
    display: table;
}

.w-rich-text::after {
    content: "";
    clear: both;
    display: table;
}

.w-rich-text::before {
    content: "";
    display: table;
}

.w-nav::after {
    clear: both;
    content: "";
    display: table;
  }

.w-nav::before {
    content: "";
    display: table;
}

.wInput {
  width: 100%;
  height: 38px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 8px 12px;
    padding-right: 12px;
    padding-left: 12px;
  font-size: 14px;
  line-height: 1.42857;
  display: block;
}

.togglebar::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 100%;
    width: 100%;
    height: 100%;
    margin-right: 15px;
    background: #000;
  }

.contact-section::after {
    content: "";
    @apply w-full;
    @apply h-full;
    @apply absolute;
    @apply box-border;
    @apply left-0;
    @apply top-0;
    @apply pointer-events-none;
    @apply border-transparent;
    @apply border-none;
    border-width: 0;
    border-radius: inherit;
    border: 0;
  }
  
.content[data-border="true"]::after {
    content: "";
    border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
    border-color: var(--border-color, none);
    border-style: var(--border-style, none);
    @apply w-full;
    @apply h-full;
    @apply absolute;
    @apply box-border;
    @apply left-0;
    @apply top-0;
    border-radius: inherit;
    pointer-events: none;
}

.content-inner[data-border="true"]::after {
    content: "";
    border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
    border-color: var(--border-color, none);
    border-style: var(--border-style, none);
    @apply w-full;
    @apply h-full;
    @apply absolute;
    @apply box-border;
    @apply left-0;
    @apply top-0;
    border-radius: inherit;
    pointer-events: none;
}


@keyframes move {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(100px, 100px);
    }
}

@keyframes fade {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes glitch-move {
    0%, 7%, 13%, 27%, 41%, 53%, 64%, 75%, 83%, 96% {
      opacity: 1;
      transform: translate(0);
    }
    4%, 10%, 23%, 35%, 47%, 58%, 67%, 78%, 88%, 100% {
      opacity: 0;
      transform: translate(-4px, -4px);
    }
  }
  
  

@keyframes letterGlitch {
    0%, 30%, 50%, 70%, 90% {
      opacity: 1;
      background: transparent;
    }
    20%, 40%, 60%, 80%, 100% {
      opacity: 0;
    }
  }

  .letterGlitch {
    animation: letterGlitch 5s step-end infinite;
  }


  

  .letterGlitch::before {
    content: 'k';
    position: absolute;
    top: -5px;
    left: -2px;
    color: rgb(62, 203, 247);
    -webkit-animation: glitch-move 2s step-end infinite;
    animation: glitch-move 2s step-end infinite;
    z-index: -1;
}  
.letterGlitch::after {
    content: 'k';
    position: absolute;
    top: -2px;
    left: -1px;
    color: rgb(226, 62, 247);
    -webkit-animation: glitch-move 2s .8s step-end reverse infinite;
    animation: glitch-move 2s .8s step-end reverse infinite;
    animation-delay: .7s;
    z-index: -1;
}  


.section:first-of-type::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}


@keyframes glitch-anim-text {
  0% {
      -webkit-transform: translate3d(calc(-1*10px),0,0) scale3d(-1,-1,1);
      transform: translate3d(calc(-1*10px),0,0) scale3d(-1,-1,1);
      -webkit-clip-path: polygon(0 20%,100% 20%,100% 21%,0 21%);
      clip-path: polygon(0 20%,100% 20%,100% 21%,0 21%);
  }
  2% {
      -webkit-clip-path: polygon(0 33%,100% 33%,100% 33%,0 33%);
      clip-path: polygon(0 33%,100% 33%,100% 33%,0 33%);
  }
  4% {
      -webkit-clip-path: polygon(0 44%,100% 44%,100% 44%,0 44%);
      clip-path: polygon(0 44%,100% 44%,100% 44%,0 44%);
  }
  5% {
      -webkit-clip-path: polygon(0 50%,100% 50%,100% 20%,0 20%);
      clip-path: polygon(0 50%,100% 50%,100% 20%,0 20%);
  }
  6% {
      -webkit-clip-path: polygon(0 70%,100% 70%,100% 70%,0 70%);
      clip-path: polygon(0 70%,100% 70%,100% 70%,0 70%);
  }
  7% {
      -webkit-clip-path: polygon(0 80%,100% 80%,100% 80%,0 80%);
      clip-path: polygon(0 80%,100% 80%,100% 80%,0 80%);
  }
  8% {
      -webkit-clip-path: polygon(0 50%,100% 50%,100% 55%,0 55%);
      clip-path: polygon(0 50%,100% 50%,100% 55%,0 55%);
  }
  9% {
      -webkit-clip-path: polygon(0 70%,100% 70%,100% 80%,0 80%);
      clip-path: polygon(0 70%,100% 70%,100% 80%,0 80%);
  }
  9.9% {
      -webkit-transform: translate3d(calc(-1*10px),0,0) scale3d(-1,-1,1);
      transform: translate3d(calc(-1*10px),0,0) scale3d(-1,-1,1);
  }
  10%, 100% {
      -webkit-transform: translate3d(0,0,0) scale3d(1,1,1);
      transform: translate3d(0,0,0) scale3d(1,1,1);
      -webkit-clip-path: polygon(0 0,100% 0,100% 100%,0 100%);
      clip-path: polygon(0 0,100% 0,100% 100%,0 100%);
  }
}


.heroContainer {
  margin: 0;
  max-width: 100%;
  flex: 0 0 auto;
  font-size: 3em;
  font-weight: bold;
  line-height: 1.1;
  color: white;
  -webkit-animation-delay: calc(2s + 4s*.2);
  -webkit-box-flex: 0;
  -webkit-animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: glitch-anim-text;
  -webkit-animation-timing-function: linear;
  animation-delay:calc(2s + 4s*.2);
  animation-name: glitch-anim-text;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  word-break: break-word;
}

@media (min-width: 768px) {
  .heroContainer {
    font-size: 3.8675em;
  }
}

@media (min-width: 1024px) {
  .heroContainer {
    font-size: 4.6875em;
  }
}

@media (min-width: 1280px) {
  .heroContainer {
    font-size: 6rem;
  }
}

.aboutUsLoader {
  pointer-events: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  max-height: 0%;
  overflow: hidden;
  background-color: white;
  opacity: 0;
  transition: opacity 0.8s, max-height 0s .8s;
}

.hideLoader {
  pointer-events: none;
  max-height: 100%;
  opacity: 1;
  transition-delay: 0s, 0s;
}

.mainLoader {
  pointer-events: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  max-height: 0%;
  overflow: hidden;
  background-color: black;
  opacity: 0;
  transition: opacity 0.8s, max-height 0s .8s;
}

.mainhideLoader {
  pointer-events: none;
  max-height: 100%;
  opacity: 1;
  transition-delay: 0s, 0s;
}


.canvasContainer {
  pointer-events: none;
  z-index: 0;
  top: -20%;
  left: 0%;
  bottom: auto;
  right: 0%;
  width: 100%;
  height: 55vh;
  object-fit: cover;
  position: absolute;
  margin-top: 6rem;
}

@media screen and (max-width: 991px) {
    .canvasContainer {
    display: block;
  }
}


@keyframes blink {

  0%, 100% {
     opacity: 0;
  }
  
  50% {
     opacity: 1;
  }
  
  }
  

  .blink {
    -webkit-animation: blink backwards 1s steps(1) infinite;
    animation: blink backwards 1s steps(1) infinite;
  }


  .slider * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    }


  .inputField:focus ~ .inputLabel,
  .activeLabel {
    top: -.3rem;
    transform: translate(-0.5rem, -50%) scale(0.9);
  }

.purpleBorder {
  @apply border-purple-500
}



.navLink::after {
  border-color: currentcolor transparent;
  border-style: solid;
  border-width: .4em .4em 0;
  content: "";
  margin-left: .3em;
  position: relative;
  top: 2px;
}

.modal {
  position: absolute;
  padding: 3rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  margin-top: 3.7rem;
  overflow-y: auto;
  border: 1px solid rgb(209, 213, 219);
  background-color: rgba(0, 0, 0, 0.384);
  display: flex;
  z-index: 9999;
  overflow: hidden;
}

.modalContent {
  position: absolute;
  top: 0;
  right: 1rem;
  max-width: 40rem;
  height: fit-content;
  min-width: 10rem;
  min-height: 10rem;
  max-height: 80vh; 
  overflow-y: auto; 
  background-color: rgba(25, 21, 28, 0.86);
  border: 1px solid #cccccc59;
  padding: 10px;
  border-radius: 4px;
}

.closeButton {
  position: absolute;
  top: .2rem;
  right: 1rem;
}


.blogFilterOption {
  text-transform: uppercase;
  cursor: pointer;
  background-color: rgba(118, 26, 155, 0.301);
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: .3125rem;
  justify-content: center;
  align-items: center;
  padding: .6rem .625rem .5rem;
  text-decoration: none;
  transition: background-color .35s cubic-bezier(.25, .46, .45, .94), border-color .2s cubic-bezier(.25, .46, .45, .94);
  display: flex;
}

@media screen and (max-width: 767px) {
  .blogFilterOption {
    margin-bottom: .625rem;
    margin-left: .3125rem;
    margin-right: .3125rem;
  }
}

@media screen and (max-width: 1024px) {
  .blogFilterOption {
    padding: .4375rem .8125rem;
  }
}

.blogSearchContainer {
  width: 100%;
  max-width: 15.625rem;
  border: 2px solid #535aee;
  border-radius: 4.5rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .blogSearchContainer {
    margin-left: 1rem;
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

@media screen and (max-width: 767px) {
  .blogSearchContainer {
    max-width: none;
    margin-left: 0;
  }
}

.blogSearchInput {
  width: 100%;
  height: 100%;
  color: #131722;
  background-color: rgba(255, 255, 255, 0);
  border: 0 #000;
  outline-style: none;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 1rem;
}

.linkArrowRight {
  width: 1.25rem;
  height: .9375rem;
  transform-origin: 0%;
  color: #000000;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-left: .625rem;
  transition: transform .2s;
  display: flex;
}

.blogLinkItemContainer {
  width: 100%;
  height: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: #fff;
  flex-direction: column;
  grid-template-rows: max-content auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  display: grid;
  box-shadow: 0 0 2.125rem 1.25rem rgba(0, 0, 0, .05);
}

@media screen and (max-width: 1024px) {
  .blogLinkItemContainer {
    border-radius: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .blogLinkItemContainer {
    border-radius: 2.5rem;
  }
}
.blogLinkItemContainer.v3 {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: max-content;
  grid-template-columns: max-content 1fr;
  text-decoration: none;
  box-shadow: 0 0 2.125rem 1.25rem rgba(0, 0, 0, .05);
}
.blogLinkItemContainer.v4 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .blogLinkItemContainer.v3 {
    grid-template-rows: max-content auto;
    grid-template-columns: 1fr;
  }
}

.blogThumnailContainer {
  grid-column-gap: .625rem;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.blogRecentPostList {
  width: 100%;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .blogRecentPostList {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .blogRecentPostList {
    grid-template-columns: 1fr;
  }
}


.active {
  background-color: rgba(77, 8, 80, 0.411);
}

.socialShareContainer {
  width: 1.125rem;
  height: 1.25rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .socialShareContainer {
    width: 1.125rem;
    height: 1rem;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }
}

.blogContentBody {
  z-index: 0;
  width: 100%;
  max-width: 65rem;
  box-shadow: none;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125rem;
  line-height: 155.556%;
  position: relative;
}

@keyframes pageExpand {
  from {
    transform: scaleY(0);
    transform-origin: bottom;
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    transform-origin: bottom;
    opacity: 1;
  }
}

@keyframes buttonOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes buttonFold {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes pageFold {
  from {
    transform: scaleY(1);
    transform-origin: top;
    opacity: 1;
  }
  to {
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
  }
}


.page-transition {
  animation: pageExpand .4269s ease-in-out;
}

.page-transition-out {
  animation: pageFold .4269s ease-in-out;
}

.button-transition {
  animation: buttonOpacity .1s ease-in-out;
}
.button-transition-out {
  animation: buttonFold .1s ease-in-out;
}


.card {
  z-index: 1;
  width: 100%;
  grid-column-gap: 3rem;
  grid-row-gap: 16px;
  background-color: rgb(20,18,23,0.8);
  border-radius: 2.5rem;
  grid-template-rows: auto;
  grid-auto-columns: 1fr;
  margin-bottom: 6.25rem;
  padding: 6rem 5rem;
  display: grid;
  position: relative;
  box-shadow: 1px 1px 2.125rem 1.25rem rgba(24, 15, 39, 0.5);
}


@media screen and (max-width: 991px) {
  .card.emailSupport {
    grid-template-columns: 1fr 1fr;
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }
}

@media screen and (max-width: 767px) {
  .card.emailSupport  {
    grid-row-gap: 1.25rem;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 1024px) {
  .card {
    grid-row-gap: 1.875rem;
    grid-template-columns: 1fr;
    margin-bottom: 3.125rem;
    padding: 3.125rem;
  }
}

@media screen and (max-width: 767px) {
  .card {
    border-radius: 1.25rem;
    padding: 1.875rem 1.25rem;
  }
}

.cardContent {
  grid-row-gap: 1.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-items: end;
  display: grid;
}

@media screen and (max-width: 767px) {
  .cardContent {
    grid-row-gap: 1rem;
  }
}

.cardContentContain {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

@media screen and (max-width: 767px) {
  .cardContentContain {
    grid-template-columns: 1fr;
  }
}

.manageAccountLink {
  width: 100%;
  max-width: 55rem;
  grid-column-gap: 2rem;
  cursor: pointer;
  border: 2px solid #fff;
    border-top-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
    border-bottom-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
  border-radius: 1.25rem;
  justify-content: space-between;
  align-items: center;
  padding: 1.875rem 2.5rem;
  text-decoration: none;
  transition: background-color .3s cubic-bezier(.25, .46, .45, .94), border-color .3s cubic-bezier(.25, .46, .45, .94);
  display: flex;
}

.manageAccountLink:hover {
  border-color: #9c32ff;
}

@media screen and (max-width: 767px) {
  .manageAccountLink {
    padding: 1rem 1.25rem;
  }
}

@media screen and (max-width: 1024px) {
  .manageAccountLink  {
    padding: 1.25rem 1.875rem;
  }
}

.manageAccountLink.selected {
  background-color: #535dee;
  border-color: #535aee;
}
.manageAccountLink.selected:hover {
  background-color: #2a35c7;
  border-color: #2932b3;
}


@media screen and (max-width: 767px) {
  .manageAccountLink.selected {
    grid-column-gap: .625rem;
  }
}

.manageAccountArrow {
  width: 1rem;
  transform-origin: 0 100%;
  color: #fff;
  justify-content: center;
  align-items: center;
  transition: transform .2s;
  display: flex;
  z-index: 10;
}

@media screen and (max-width: 767px) {
  .manageAccountArrow {
    flex: none;
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.007);
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.spinnerMobile {
  border: 4px solid rgba(255, 255, 255, 0.007);
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.cursor {
  display: inline-block;
  margin-left: 5px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}