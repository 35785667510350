.h1 {
    font-size: 32px;
    color: inherit;
    font-weight: bold;
    line-height: 1.25;
    margin: 0 0 1rem 0;
}
.h2 {
    font-size: 24px;
    color: inherit;
    font-weight: bold;
    line-height: 1.25;
    margin: 0 0 1rem 0;
}
.h3 {
    font-size: 20px;
    color: inherit;
    font-weight: bold;
    line-height: 1.25;
    margin: 0 0 1rem 0;
    margin-top: 2rem;
}

.h4 {
    font-size: 18px;
    color: inherit;
    font-weight: bold;
    line-height: 1.25;
    margin: 0 0 1rem 0;
}

.h5 {
    font-size: 14px;
    color: inherit;
    font-weight: bold;
    line-height: 1.25;
    margin: 0 0 1rem 0;
}

.p {
    margin: 0 0 calc(1.25 * 1rem);
}

.ol,
.olBullet,
.olBulletBrokers,
.olTrend {
    margin: 0 0 1rem;
    padding-left: 2rem;
}

.olBulletBrokers,
.olTrend {
    padding-bottom: 2rem;
}

.hr {
    border: 1px solid gray;
    margin: 1.5rem 0;
}

blockquote {
    border: 0 solid var(--blockquote-border-color);
    border-left: 2px solid var(--blockquote-border-color);
    box-shadow: none;
    color: var(--blockquote-color);
    font-size: 100%;
    padding: 0 1rem;
    margin: 0 0 1rem !important;
}


blockquote > :last-child {
    margin-bottom: 0;
}

blockquote > :first-child {
    margin-top: 0;
}

.code {
    background-color: rgb(177, 177, 177);
    border: .1rem solid rgba(0,0,0,.1);
    color: rgb(255, 255, 255);
    border-radius: .4rem;
    font-size: 95%;
    padding: .1rem .1rem;
    vertical-align: middle;
}

.markdown::after {
    clear: both;
    content: "";
    display: table;
  }
  
.markdown::before {
    content: "";
    display: table;
  } 

.markdown h1:first-child {
    margin-bottom: calc(1.25 * 1.25 * 1rem);
}

.markdown > p {
    margin-bottom: calc(1.25 * 1rem);
  }

.markdown > h2 {
    margin-top: calc(2 * 1rem);
}

.markdown > h2,
.markdown > h3,
.markdown > h4,
.markdown > h5,
.markdown > h6 {
    margin-bottom: calc(2 * 1rem);
}

.markdown li {
    word-wrap: break-word;
}

.markdown ol {
    list-style-type: none;
    counter-reset: listItem;
}

.markdown ol > li {
    counter-increment: listItem;
    margin-left: -15px;
}

.markdown ol > li::before {
    content: counter(listItem) ". ";
}
.markdown .olBullet > li::before {
    content: "• ";
}

.markdown > :last-child {
    margin-bottom: 0;
  }

.docsInnerLink {
    color: var(--link-color);
    text-decoration: none;
    transition: color 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.docsInnerLink:hover {
    text-decoration: underline;
}

.back-to-top {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    padding: 0;
    background-color: rgb(203 213 225);
    position: fixed;
    bottom: 1.3rem;
    height: 3rem;
    opacity: 0;
    right: 1.3rem;
    border-radius: 50%;
    transform: scale(0);
    transition: all 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
    visibility: hidden;
    width: 3rem;
    z-index: 2;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.back-to-top::after {
    background-color: rgb(47, 49, 51);
    content: " ";
    display: inline-block;
    height: 100%;
    width: 100%;
    mask: var(--menu-link-sublist-icon) 50%/2rem 2rem no-repeat;
}

.back-to-top-show {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
}

.back-to-top-show:hover {
    background-color: rgb(190, 199, 209);
}

.alertWarning {
    background-color: #fff8e6; 
    border: 0px solid transparent;
    border-left: 5px solid #ffb969;
    border-radius: .4rem;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
    padding: 1rem 1rem;
    color: #4d3800;
}

.alertWarning h5,
.infoWarning h5,
.infoWarningDark h5,
.infoTipInfo h5,
.infoTipInfoDark h5,
.danger h5,
.dangerDark h5 {
    margin-bottom: 8px;
    margin-top: 0;
    text-transform: uppercase;
  }

.alertWarningIcon {
    display: inline-block;
    margin-right: .4em;
    vertical-align: middle;
    margin-bottom: .34em;
  }

.hashLink::before {
    content: "#"
}

.infoWarning {
    background-color: rgba(84,199,236,.15); 
    border: 0px solid transparent;
    border-left: 5px solid #52cada;
    border-radius: .4rem;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
    padding: 1rem 1rem;
    color: #00414d;
    margin-bottom: 1rem;
}


.infoAlert {
    display: inline-block;
    margin-right: .4em;
    vertical-align: middle;
    margin-bottom: .29em;
    width: 22px;
    height: 22px;
    fill: #000;
    transition: fill 200ms cubic-bezier(0.08,.52,0.52,1);
  }

.infoAlertDark {
    display: inline-block;
    margin-right: .4em;
    vertical-align: middle;
    margin-bottom: .29em;
    fill: #fff;
}

.infoWarningDark {
    background-color: rgba(87, 156, 179, 0.425); 
    border: 0px solid transparent;
    border-left: 5px solid #69eeff;
    border-radius: .4rem;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
    padding: 1rem 1rem;
    color: #d9e4e6;
    margin-bottom: 1rem;
}

.infoTipInfo {
    background-color: rgb(194, 228, 196); 
    border: 0px solid transparent;
    border-left: 5px solid #52da64;
    border-radius: .4rem;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
    padding: 1rem 1rem;
    color: rgba(15, 77, 24, 0.829);
    margin-bottom: 1rem;
}

.infoTipInfoDark {
    background-color: rgba(23, 143, 39, 0.671); 
    border: 0px solid transparent;
    border-left: 5px solid #52da64;;
    border-radius: .4rem;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
    padding: 1rem 1rem;
    color: rgb(194, 228, 196);
    margin-bottom: 1rem;
}

.infoTip {
    display: inline-block;
    margin-right: .4em;
    vertical-align: middle;
    margin-bottom: .4em;
    width: 22px;
    height: 22px;
    fill: rgba(15, 77, 24, 0.829);
    transition: fill 200ms cubic-bezier(0.08,.52,0.52,1);
  }

.infoTipDark {
    display: inline-block;
    margin-right: .4em;
    vertical-align: middle;
    margin-bottom: .4em;
    fill: rgb(194, 228, 196);
}



.danger {
    background-color: rgba(236, 84, 84, 0.15); 
    border: 0px solid transparent;
    border-left: 5px solid #ff6969;
    border-radius: .4rem;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
    padding: 1rem 1rem;
    color: #4d0000;
    margin-bottom: 1rem;
}


.dangerAlert {
    display: inline-block;
    margin-right: .4em;
    vertical-align: middle;
    margin-bottom: .29em;
    width: 22px;
    height: 22px;
    fill: #4d0000;
    transition: fill 200ms cubic-bezier(0.08,.52,0.52,1);
  }

.dangerAlertDark {
    display: inline-block;
    margin-right: .4em;
    vertical-align: middle;
    margin-bottom: .29em;
    fill: #e6d9d9;
}

.dangerDark {
    background-color: rgba(252, 26, 26, 0.425); 
    border: 0px solid transparent;
    border-left: 5px solid #ff6969;
    border-radius: .4rem;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
    padding: 1rem 1rem;
    color: #e6d9d9;
    margin-bottom: 1rem;
}



.changelogLink {
    color: #00414d;
    background-color: #dfdfdf;
    border: 0;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .3);
    text-decoration: none;
    transition: box-shadow .2s;
    border-radius: 3px;
    flex-direction: row;
    font-weight: 500;
    margin-bottom: 32px;
    margin-top: 32px;
    padding: 15px 30px 15px 20px;
    position: relative;
    align-items: center;
    display: flex;
}

.changelogLink:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .5);
}

.changelogLinkDark {
    color: rgb(168 85 247);
    background-color: #111114;
    border: 0;
    box-shadow: 0 3px 8px 0 rgba(255, 255, 255, 0.3);
    text-decoration: none;
    transition: box-shadow .2s;
    border-radius: 3px;
    flex-direction: row;
    font-weight: 500;
    margin-bottom: 32px;
    margin-top: 32px;
    padding: 15px 30px 15px 20px;
    position: relative;
    align-items: center;
    display: flex;
}

.changelogLinkDark:hover {
    box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
}

.activeTab {
    border-bottom-color: rgb(85, 115, 247);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: rgb(85, 115, 247);
  }

  .activeTabDark {
    border-bottom-color: rgb(168,85,247);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: rgb(168,85,247);
  }

.link {
    color: #3db1c0;
}
.link:hover {
    text-decoration: underline;
}