.splide__arrows {
    justify-content: flex-end;
    display: flex;
    position: absolute;
    top: -7.5rem;
    bottom: auto;
    left: auto;
    right: 0%;
  }

  .splide__arrow {
    width: 3.2rem;
    height: 3.2rem;
    -webkit-text-fill-color: inherit;
    background-color: #141217;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    transition-timing-function: linear;
    display: flex;
    position: relative;
    opacity: 1;
    transform: none;
    z-index: auto;  
    top: -50%;
  }

  .splide__arrow.splide__arrow--prev {
    margin-right: .5rem;
  }

  .splide__arrow.splide__arrow--next {
    right: -1em;
  }

  
  
  .splide__arrow::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.05));
    content: '';
    z-index: -1;
    border-radius: 50%;
  }

  .splide__arrow:hover:not(:disabled) {
    opacity: .9;
    transform: scale(1.1);
}


  .splide__arrow svg {
    fill: rgba(63, 169, 245, 1);
    height: 1em;
    width: 1em
  }

  .splide button {
    color: inherit;
  }
  
  .splide button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  
  @media screen and (max-width: 479px) {
    .text-weight-medium.mobile-hide, .hide-mobile-portrait {
      display: none;
    }
  }


  @media screen and (min-width: 480px) {
    .splide__arrow {
      top: 83%;
    }
  }

  @media screen and (min-width: 1024px) {
    .splide__arrow {
      top: -37%;
    }
  }

  @media screen and (min-width: 1280px) {
    .splide__arrow {
      top: -100%;
    }
  }
